/* eslint-disable max-len */
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import {
  Documentation
} from './views';

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Documentation />} />
      </Routes>
    </BrowserRouter >
  );
};

export default App;
