import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import { useState } from 'react';

import { Header } from '../../components';

import 'highlight.js/styles/github.css';
import './styles.scss';
import Tabs from '../../components/Tabs';
import { TabItem } from '../../interfaces';

export const Profile = () => {
  const [selectedItem, setSelectedItem] = useState<number>(1);

  const renderContent = (): string => {
    const info: { [name: string]: string } = {
      1: `# COMMON DEFINITIONS

\`\`\`typescript

      export type NormCodes = '141' | '142' | '050' | '051' | '004' | '189' | '015' | '024' | '020' | '173' | '187' | '003' | '235';
      export type NormFases = 'I' | 'II' | 'III' | 'N/A';
      export type ServiceCodes = 'CC' | 'DC' | 'DIS' | 'REV' | 'DN' | 'CN';

      export interface ClientReference {
        id: string
        businessName: string
        shortName: string
      }

      export interface PersonReference {
        id: string
        name: string
      }

      export interface ServiceReference {
        id: string
        code: ServiceCodes
        number: string
      }

      export interface ProductReference {
        id: string
        code: string
        code2: string
        invoice: string
        subInvoice: string
        description: string
        fase: string
      }

      export interface InspectionReference {
        id: string
        number: string
      }

      export interface TaskReference {
        id: string
        number: string
      }

      export interface ActionEntry {
        action: string
        author: string
        date: Date
        extraInformation?: any
      }

      interface Pagination {
        currentPage: number
        perPage: number
        totalItems: number
      }

    `,
      2: `# CLIENT

\`\`\`typescript
  type ClientPersonType = 'physcial' | 'moral';
  type ClientNationalityType = 'national' | 'international';
  type ClientStatus = 'active' | 'inactive';
  type TaxSystem = '601'
  | '603'
  | '605'
  | '606'
  | '608'
  | '609'
  | '610'
  | '611'
  | '612'
  | '614'
  | '616'
  | '620'
  | '621'
  | '622'
  | '623'
  | '624'
  | '628'
  | '607'
  | '629'
  | '630'
  | '615'
  | '625'
  | '626';

  interface ClientContact {
    name: string
    email: string
    phone: string
  }

  interface ClientAddress {
    id: number
    street: string
    number: string
    neighborhood: string
    city: string
    postalCode: string
    state: string
    country: string
  }

  class Client {
    id: string
    rfc: string
    shortName: string
    businessName: string
    contractNumber: string
    contractDate: Date
    legalRepresentative: string
    personType: ClientPersonType
    nationalityType: ClientNationalityType
    contact: ClientContact
    fiscalAddress: ClientAddress
    verificationAddresses: ClientAddress[]
    collaborators: _Collaborator[]
    status: ClientStatus
    signedContract: boolean
    tax_system: TaxSystem
    creditDays: number
    createdAt: Date
    updatedAt: Date
  }
  \`\`\`



  # ME

  **Endpoint**: /api/v2/public/clients/me

  **Método**: GET

  **Query**: no aplica

  **Body**: no aplica

  **Response**:

  \`\`\`typescript
  interface Client
  \`\`\`

  **Errores**:

  | Estatus | Código                  | Significado                 |
  |---------|-------------------------|-----------------------------|
  | 404     | CLIENT_WAS_NOT_FOUND    | No se encontró el cliente  |
  `,
      3: `# COLLABORATOR

\`\`\`typescript
  export interface ClientReference {
    id: string
    businessName: string
    shortName: string
  }

  type ToursDone =
  'binnacle'
  | 'constancy-request'
  | 'dictum-request'
  | 'revision-request'
  | 'inspection'
  | 'approve-inspection'
  | 'create-task'
  | 'start-on-site-task'
  | 'close-on-site-task'
  | 'service-detail'
  | 'main-menu';

  class Collaborator {
    id: string
    email: string
    password: string
    name: string
    phone: string
    client: ClientReference
    isActive: boolean
    toursDone: ToursDone[]
    createdAt: Date
    updatedAt: Date
  }
  \`\`\`

  # ME

  **Endpoint**: /api/v2/public/collaborator/me

  **Método**: GET

  **Query**: no aplica

  **Body**: no aplica

  **Response**:
  \`\`\`typescript
  interface Collaborator
  \`\`\`

  **Errores**:
  | Estatus | Código                  | Significado                 |
  |---------|-------------------------|-----------------------------|
  | 404     | COLLABORATOR_WAS_NOT_FOUND    | No se encontró el colaborador  |
`,
      4: `# CONSTANCY SERVICE

\`\`\`typescript
  type ConstancyServiceStatus =
  'inspecting-products'
  | 'concluded'
  | 'deleted';

  interface InvoicedInfo {
    invoiceNumber: string
    date: Date | null
    UUID: string
  }

  interface ConstancyServiceIndicators {
    requestIsCompleted: boolean
    invoiced: boolean
    invoicedInfo: InvoicedInfo,
    urgent: boolean
  }

  class ConstancyService {
    id: string
    requestNumber: string
    status: ConstancyServiceStatus
    client: ClientReference
    collaborator: PersonReference
    proposedValidationDate: Date | null
    products: ConstancyProduct[]
    indicators: ConstancyServiceIndicators
    tracking: ActionEntry[]
    actionsEnabled: ConstancyServiceActionsEnabled[]
    createdAt: Date
    updatedAt: Date
  }
  \`\`\`

  # Create Constancy Service
  **Método**: POST

  **Endpoint**: /api/v2/public/constancy-services

  **Body**: NO aplica

  **Respuesta**:

  \`\`\`tsx
  interface ConstancyService
  \`\`\`

  **Errores**:
  | Estatus | Código                    | Significado                                                                 |
  |---------|---------------------------|-----------------------------------------------------------------------------|
  | 404     | CLIENT_WAS_NOT_FOUND       | El cliente no existe                                                         |
  | 404     | COLLABORATOR_WAS_NOT_FOUND | El colaborador no existe                                                     |
  | 400     | SERVICE_WAS_NOT_CREATED    | Por un error desconocido, no se pudo guardar el servicio                     |

  # Delete Constancy Service

**Método**: DELETE

**Endpoint**: api/v2/public/constancy-services/:serviceID

**Body**: No aplica

**Response**:

\`\`\`typescript
interface ConstancyService
\`\`\`

**Errores**:
| Estatus | Código                                        | Significado                                                                 |
|---------|-----------------------------------------------|-----------------------------------------------------------------------------|
| 404     | SERVICE_WAS_NOT_FOUND                        | El servicio no existe                                                        |
| 400     | PRODUCTS_ARE_NOT_ABLE_TO_BE_DELETED          | Debido a su estatus, los productos no pueden ser eliminados                  |
| 400     | SERVICE_IS_NOT_ABLE_TO_BE_DELETED            | Debido a su estatus, el servicio no puede ser eliminado                      |
| 400     | PRODUCTS_WERE_NOT_DELETED                    | Por un error desconocido, los productos no pudieron eliminarse               |
| 400     | CANNOT_DELETE_SERVICE                      | Por un error desconocido, el servicio no pudo eliminarse                     |
| 400     | CANNOT_UPDATE_SERVICE_BY_INVOICED                         | El servicio no puede eliminarse porque ya se facturó                     |

# Get service by ID

**Método**: GET

**Endpoin**: api/v1/public/constancy-services/:serviceID

**Body**: No aplica

**Respuesta**:

\`\`\`typescript
interface ConstancyService
\`\`\`

**Errores**:
| Estatus | Código                 | Significado        |
|---------|------------------------|--------------------|
| 404     | SERVICE_WAS_NOT_FOUND   | El servicio no existe |


# Get services

**Método**: GET

**Endpoint**: api/v2/public/constancy-services

**Body**: No aplica

**Query**:


Propiedad iniciando por el tipo seguido de guión bajo:
\`\`\`typescript
type 'n' | 'b' | 's' 
number, boolean & string
filters: {
      s_requestNumber: '',
      s_createdAt: '2024-11-07',
      s_updatedAt: '2024-11-07',
      s_proposedValidationDate: '2024-11-07',
      s_expirationDate: '2024-11-07',
      b_requestIsCompleted: 'true',
      s_collaboratorName: '',
      s_clientShortName: '',
      s_clientBusinessName: '',
      s_status: '',
      s_serviceCode: '',
      s_type: '',
      b_lookInTheTrash: ''
},
pagination: {
  n_currentPage: 1,
  n_perPage: 10,
  n_totalItems: 10
},
ordering: {
  s_orderBy: '',
  s_orderDirection: 'ASC' | 'DESC'
}
\`\`\`

**Respuesta**:

\`\`\`typescript

{
  items: interface ConstancyService[],
  pagination: interface Pagination
}
\`\`\`

**Errores**:
| Estatus | Código                    | Significado                                                       |
|---------|---------------------------|-------------------------------------------------------------------|
| 404     | SERVICES_WERE_NOT_FOUND    | No se encontraron servicios con los criterios de búsqueda         |

# Update constancy service

**Principalmente para actualizar el indicador**: \`\`\`requestIsCompleted\`\`\`, para definir que ya se puede continuar con acciones posteriores del servicio, como asignar inspectores

**Método**: PUT

**Endpoint**: api/v2/public/constancy-services/:serviceID

**Body**:

\`\`\`typescript
{
  type: 'object',
  properties: {
    requestIsCompleted: {
      type: 'boolean'
    }
  }
}
\`\`\`

**Response**:

\`\`\`typescript
interface ConstancyService
\`\`\`

**Errores**:
| Estatus | Código                    | Significado                                                        |
|---------|---------------------------|--------------------------------------------------------------------|
| 404     | SERVICE_WAS_NOT_FOUND      | El servicio no existe.                                             |
| 400     | SERVICE_WAS_NOT_UPDATED    | Por un error desconocido, el servicio no pudo actualizarse.       |
| 400     | CANNOT_UPDATE_SERVICE_BY_INVOICED    | El servicio no se pudo actualizar porque ya se facturó       |
| 400     | SERVICE_ALREADY_COMPLETED    | No se pudo actualizar porque ya se completó la creación de la solicitud       |
| 400     | SERVICE_DOES_NOT_HAVE_PRODUCTS    | No se pudo actualizar porque el servicio no tiene productos dados de alta       |

# Generate All Constancies PDF

Método: GET

Endpoint: /api/v2/public/constancy-services/:serviceID/bulk/constancy-of-inspection

Respuesta:

\`\`\`typescript
data {
      files[invoice]: Buffer
}
\`\`\`

Errores:
| Estatus | Código                     | Significado                                                       |
|---------|----------------------------|-------------------------------------------------------------------|
| 404     | NO_IMAGES_FOUND            | No se encontraron las imágenes                                   |
| 500     | COULD_NOT_GENERATE_DOCUMENT | Debido a un error inesperado, no se pudo generar el documento    |
| 400     | PRODUCTS_WERE_NOT_FOUND | No se encontraron productos de constancia    |
| 400     | NO_INVOICE_IS_CONCLUDED | El servicio no esta concluido, por ende no se pueden generar los documentos    |
| 400     | DOCUMENT_EMPTY | Debido a un error desconocido el/los documento está vacío    |

`,
      5: `# CONSTANCY PRODUCT
\`\`\`typescript
export type ConstancyProductStatus =
  'in-inspection'
  | 'concluded'
  | 'deleted';

export interface ConstancyProductIndicators {
  documentReleaseDate: Date | null
  design: boolean
  package: string
  long: string
  high: string
  radio: string
  urgent: boolean
  NOM003Complement: boolean
}

export type ConstancyProductActionsEnabled = 'edit-products'
  | 'upload-files'
  | 'delete-invoice'
  | 'assign-products';

class ConstancyProduct {
  id: string
  invoice: string
  status: ConstancyProductStatus
  client: ClientReference
  collaborator: PersonReference
  service: ServiceReference
  inspector: PersonReference | null
  approver: PersonReference | null
  inspection: InspectionReference | null
  task: TaskReference | null
  normCode: NormCodes
  fase: NormFases
  code: string
  brand: string
  presentation: string
  description: string
  documents: string[]
  tracking: ActionEntry[]
  indicators: ConstancyProductIndicators
  round: number
  actionsEnabled: ConstancyProductActionsEnabled[]
  createdAt: Date
  updatedAt: Date
}
\`\`\`

# Create Constancy Products

**Endpoint**: /api/v2/public/constancy-products

**Métod**o: POST

**Body**:

\`\`\`typescript
{
  type: 'object',
  required: ['constancyServiceID', 'products'],
  properties: {
    constancyServiceID: {
      type: 'string',
      minLength: 1
    },
    products: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        required: ['normCode', 'fase', 'code', 'brand', 'presentation', 'description'],
        properties: {
          normCode: {
            type: 'string',
            enum: [
              '004',
              '189',
              '141',
              '142',
              '050',
              '051',
              '015',
              '024',
              '020',
              '173',
              '187',
              '235']
          },
          fase: {
            type: 'string',
            enum: ['II', 'III', 'N/A']
          },
          code: {
            type: 'string',
            minLength: 1
          },
          brand: {
            type: 'string',
            minLength: 1
          },
          presentation: {
            type: 'string',
            minLength: 1
          },
          description: {
            type: 'string',
            minLength: 1
          },
          design: {
            type: 'boolean'
          },
          NOM003Complement: {
            type: 'boolean' // only NOM-050
          }
        }
      }
    }
  }
}
\`\`\`

**Respuesta**:

\`\`\`typescript
interface ConstancyProduct[]
\`\`\`

**Errores**
| Estatus | Código                     | Significado                                                                                     |
|---------|----------------------------|-------------------------------------------------------------------------------------------------|
| 404     | SERVICE_WAS_NOT_FOUND      | El servicio no existe, podría ser debido a que no le pertenece al cliente en la API pública     |
| 400     | INVALID_FASE_FOR_NORM_051  | Para la norma 051 solo se aceptan las fases 1, 2 y 3 en números romanos                         |
| 400     | PRODUCTS_WERE_NOT_CREATED  | Debido a un error desconocido, no fue posible guardar los productos                             |
| 400     | CANNOT_CREATE_PRODUCT_BY_INVOICED  | No se puede crear el producto ya que el servicio ya está facturado                             |

# Delete Constancy Products

Endpoint: /api/v2/public/constancy-products

Método: DELETE

Query:

\`\`\`typescript
{
  type: 'object',
  properties: {
    deleteAll: {
      type: 'boolean'
    },
    serviceID: {
      type: 'string'
    },
    productID: {
      type: 'string'
    }
  }
}
\`\`\`

Body: no aplica

Respuesta:

\`\`\`typescript
interface ConstancyProduct[]
\`\`\`

Errores:
| Estatus | Código                              | Significado                                                                       |
|---------|-------------------------------------|-----------------------------------------------------------------------------------|
| 404     | PRODUCTS_WERE_NOT_FOUND            | No se encontraron los productos, puede ser porque no le pertenecen al cliente    |
| 400     | PRODUCT_WAS_NOT_DELETED            | Por un error desconocido, el producto no pudo eliminarse                         |
| 400     | CANNOT_DELETE_INVOICE| Debido a su status, el producto no puede eliminarse                               |
| 400     | CANNOT_MODIFY_PRODUCT_BY_INVOICED| No se puede eliminar el producto ya que el servicio ya se facturó                               |

# Get constancy product by ID

Endpoint: /api/v2/public/constancy-products/:productID

Body: no aplica

Respuesta:

\`\`\`typescript
interface ConstancyProduct
\`\`\`

Errores:
| Estatus | Código                | Significado                                                             |
|---------|-----------------------|-------------------------------------------------------------------------|
| 404     | PRODUCT_WAS_NOT_FOUND | El producto no existe |

# Get constancy products

Endpoint: /api/v2/public/constancy-products

Método: GET

Query:

Propiedad iniciando por el tipo seguido de guión bajo:
\`\`\`typescript
type 'n' | 'b' | 's' 
number, boolean & string
filters {
      s_invoice: '',
      s_status: '',
      s_clientBusinessName: '',
      s_clientShortName: '',
      s_collaboratorName: '',
      s_requestNumber: '',
      s_serviceID: '',
      s_inspectorName: '',
      s_inspectionNumber: '',
      b_indicatorsDesign: '',
      b_indicatorsUrgent: '',
      s_taskNumber: '',
      s_normCode: '',
      s_fase: '',
      s_code: '',
      s_brand: '',
      s_presentation: '',
      s_description: '',
      s_createdAt: '',
      s_updatedAt: '2024-11-02',
      b_lookInTheTrash: 'true'
},
pagination: {
  n_currentPage: 1,
  n_perPage: 10,
  n_totalItems: 10
},
ordering: {
  s_orderBy: '',
  s_orderDirection: 'ASC' | 'DESC'
}
\`\`\`

Body: no aplica

Respuesta:

\`\`\`typescript
{
  items: interface ConstancyProduct[]
  pagination: interface Pagination
}
\`\`\`

Errores:
| Estatus | Código                | Significado                                     |
|---------|-----------------------|-------------------------------------------------|
| 404     | PRODUCTS_WERE_NOT_FOUND | No se encontraron productos bajo el criterio de búsqueda |

# Update constancy product

Endpoint: /api/v2/public/constancy-products/:productID

Método: PUT

Body:

\`\`\`typescript
{
  type: 'object',
  required: ['normCode', 'fase', 'code', 'brand', 'presentation', 'description'],
  properties: {
    normCode: {
      type: 'string',
      enum: [
        '004',
        '189',
        '141',
        '142',
        '050',
        '051',
        '015',
        '024',
        '020',
        '173',
        '187',
        '235']
    },
    fase: {
      type: 'string',
      enum: ['II', 'III', 'N/A']
    },
    code: {
      type: 'string',
      minLength: 1
    },
    brand: {
      type: 'string',
      minLength: 1
    },
    presentation: {
      type: 'string',
      minLength: 1
    },
    description: {
      type: 'string',
      minLength: 1
    },
    design: {
      type: 'boolean'
    },
    NOM003Complement: {
      type: 'boolean' // only NOM-050
    }
  }
}
\`\`\`

Respuesta:

\`\`\`typescript
interface ConstancyProduct
\`\`\`

Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 404     | PRODUCT_WAS_NOT_FOUND      | El producto no existe, puede ser debido a que no le pertenece al cliente     |
| 400     | INVALID_FASE_FOR_NORM_051  | Para la nom 051 se admiten solo la fase 2 y 3 en romano                   |
| 400     | PRODUCT_WAS_NOT_UPDATED    | Debido a un error desconocido, el producto no se pudo actualizar             |
| 400     | CANNOT_UPDATE_PRODUCT  | Debido a su estado, el producto no pudo ser actualizado                      |
| 400     | VALIDATION_ERROR  | Los datos no fueron enviados correctamente                      |

# Upload Product Documents

Endpoint que genera una URL firmada para subir el archivo especificado al almacenamiento de AWS S3

Método: POST

Endpoint: /api/v2/public/constancy-products/:productID/documents

Body: 
\`\`\`typescript
{
  type: 'object',
  required: ['filePaths'],
  properties: {
    filePaths: {
      type: 'array',
      minItems: 1,
      required: ['path', 'type'],
      properties: {
        path: {
          type: 'string'
        },
        type: {
          type: 'string' // MIME Type, example: application/pdf
        }
      }
    }
  }
}

Example:
{
  documentName: "image-01.jpg",
  path: "image-01.jpg",
  type: "image/jpeg"
}
\`\`\`


Respuesta:

\`\`\`typescript
SignedURL {
  url: string
  signedURL: string | undefined
  type: string
  documentName: string
}

SignedURL[]
\`\`\`

Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 400     | FILE_WAS_NOT_SENT      | El archivo no se envió o se envió vacío     |
| 400     | PRODUCT_DONT_ABLE_TO_UPLOAD_DOCUMENTS  | Debido al estatus del producto no se admite la subida de archivos                   |
| 400     | DOCUMENT_ALREADY_EXIST    | El documento ya se ha subido, ya existe             |
| 400     | GENERATE_PRE_SIGNED_URL_ERROR  | Debido a un eror desconocido no se pudo generar la URL firmada                     |

# Add uploaded document path

Este endpoint debe ser llamado después de que la subida del archivo por medio de la URL firmada se haya completado con éxito,
el path del documento es la propiedad "url" de la respuesta del endpoint para generar la URL firmada

Método: PUT

Endpoint: /api/v2/public/constancy-products/:productID/update-documents-paths

Body: 
\`\`\`typescript
{
  type: 'object',
  required: ['filePaths'],
  properties: {
    filePaths: {
      type: 'array',
      minLength: 1,
      itemsType: 'string
    }
  }
}
\`\`\`

Respuesta:

\`\`\`typescript
interface ConstancyProduct
\`\`\`

# Delete document

Método: DELETE

Endpoint: /api/v2/public/constancy-products/:productID/documents

Body: 
\`\`\`typescript
{
  type: 'object',
  required: ['documentPath'],
  properties: {
    documentPath: {
      type: 'string',
      minLength: 1
    }
  }
}

Example:
{
  s_documentPath: "clients/66cce34f55fc4900304c22f0/revision-products/66eb167c2b184c0030bf783b/round-1IMG-20240829-WA0054.jpg"
}
\`\`\`

Respuesta:

\`\`\`typescript
interface ConstancyProduct
\`\`\`


Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 400     | DOCUMENT_WAS_NOT_FOUND      | El archivo no se encontró     |

# Mark changed files

Endpoint para marcar como subidos archivos para nueva ronda de un producto de constancia, ésto nos indica que podemos seguir con la siguiente ronda de inspección

Método: PUT

Endpoint: /api/v2/public/constancy-products/:productID/mark-changed-files

Body: No aplica

Respuesta:

\`\`\`typescript
interface ConstancyProduct
\`\`\`


Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 400     | ROUND_DOCUMENTS_ALREADY_UPLOADED      | Los documentos de la ronda ya se han subido     |

# Generate Constancy

Método: GET

Roles permitidos: master, operador, colaborador

Endpoint: /api/v2/public/constancy-products/:productID/constancy-of-inspection

Respuesta:

\`\`\`typescript
file: {
      data: Buffer // aplication/pdf
}
\`\`\`

Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 400     | PRODUCT_IS_NOT_CONCLUDED      | El producto no está concluido, no se pude generar el documento     |
| 400     | CANNOT_GENERATE_CONSTANCY_WHITOUT_INSPECTION_LIST      | No se puede generar la constancia si no existe lista de inspección     |
| 400     | CANNOT_GENERATE_CONSTANCY_FROM_EXPIRED_INVOICE      | No se puede generar constancia de un producto expirado     |
| 400     | CANNOT_GENERATE_DICTUM_CLIENT_NOT_HAVE_SIGNED_CONTRACT      | No se puede generar constancia si el cliente no ha firmado contrato     |
| 400     | CANNOT_GENERATE_DOCUMENT_NOT_SIGNED_CONTRACT      | No se puede generar constancia si el cliente no ha firmado contrato     |

`,
      6: `# DICTUM SERVICE
\`\`\`typescript
type DictumServiceStatus =
  'validating-invoices'
  | 'inspecting-products'
  | 'concluded'
  | 'deleted';

interface DictumServiceIndicators {
  requestIsCompleted: boolean
  isReadyForVisit: boolean
  waitingToExpired: boolean
  invoiced: boolean
  invoicedInfo: InvoicedInfo
}

export type DictumServiceActionsEnabled = 'edit-service'
  | 'assign-out-of-customs-date'
  | 'request-inspection'
  | 'delete-service'
  | 'add-product'
  | 'edit-products'
  | 'upload-paid-petition'
  | 'upload-rectified-petition'
  | 'validate-products'
  | 'assign-products'
  | 'register-invoiced';

class DictumService {
  id: string
  petitionNumber: string
  rectifiedPetitionNumber: string | null
  status: DictumServiceStatus
  documents: string[]
  client: ClientReference
  collaborator: PersonReference
  inspectionAddress: string
  proposedValidationDate: Date | null
  outOfCustomsDate: Date | null
  products: DictumProduct[]
  indicators: DictumServiceIndicators
  tracking: ActionEntry[]
  actionsEnabled: DictumServiceActionsEnabled[]
  createdAt: Date
  updatedAt: Date
}
\`\`\`

# Create Dictum Service

Método: POST

Endpoint: /api/v2/public/dictum-services

Body:

\`\`\`typescript
{
  type: 'object',
  required: ['petitionNumber', 'inspectionAddress'],
  properties: {
    petitionNumber: {
      type: 'string',
      pattern: '^[0-9]{2} [0-9]{2} [0-9]{4} [0-9]{7}$'
    },
    inspectionAddress: {
      type: 'string',
      minLength: 1
    }
  }
}
\`\`\`

Respuesta:

\`\`\`typescript
interface DictumService
\`\`\`

Errores:
| Estatus | Código                          | Significado                                           |
|---------|---------------------------------|-------------------------------------------------------|
| 404     | CLIENT_WAS_NOT_FOUND           | El cliente no existe                                 |
| 404     | COLLABORATOR_WAS_NOT_FOUND     | El colaborador no existe                             |
| 409     | PETITION_NUMBER_ALREADY_EXIST  | El número de pedimento ya existe                     |
| 400     | DICTUM_SERVICE_WAS_NOT_CREATED | Por un error desconocido, no se pudo guardar el servicio |

# Delete Dictum Service

Método: DELETE

Endpoint Admin: api/v2/public/dictum-services/:dictumServiceID

Body: No aplica

Response:

\`\`\`typescript
interface DictumService
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 404     | SERVICE_WAS_NOT_FOUND             | El servicio no existe                                     |
| 400     | PRODUCTS_ARE_NOT_ABLE_TO_BE_DELETED | Debido a su estatus, los productos no pueden ser eliminados |
| 400     | CANNOT_DELETE_SERVICE | Debido a su estatus, el servicio no puede ser eliminado   |
| 400     | PRODUCTS_WERE_NOT_DELETED         | Por un error desconocido, los productos no pudieron eliminarse |
| 400     | SERVICE_WAS_NOT_DELETED           | Por un error desconocido, el servicio no pudo eliminarse  |
| 400     | DOCUMENTS_WERE_NOT_DELETED        | Por un error desconocido, los documentos no pudieron ser eliminados |
| 400     | CANNOT_UPDATE_SERVICE_BY_INVOICED        | El servicio no puede ser eliminado porque ya se facturó |

# Get service by ID

Método: GET

Endpoint: api/v1/public/dictum-services/:dictumServiceID

Body: No aplica

Respuesta:

\`\`\`typescript
interface DictumService
\`\`\`

Errores:

| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 404     | SERVICE_WAS_NOT_FOUND             | El servicio no se encontró                                     |

# Get dictum services

Método: GET

Endpoint: api/v2/public/dictum-services

Body: No aplica

Query:

Propiedad iniciando por el tipo seguido de guión bajo:

\`\`\`typescript
type 'n' | 'b' | 's' 
number, boolean & string
filters {
  s_petitionNumber: '',
  s_rectifiedPetitionNumber: '',
  s_status: '',
  s_clientBusinessName: '',
  s_clientShortName: '',
  s_collaboratorName: '',
  s_inspectionAddress: '',
  s_modelsQuantity: '',
  s_daysToClose: '',
  s_proposedValidationDate: '',
  s_outOfCustomsDate: '',
  s_requestIsCompleted: '',
  b_isReadyForVisit: '',
  b_invoiced: '',
  s_invoiceNumber: '',
  s_invoicedInfoDate: '',
  s_UUID: '',
  s_createdAt: '2024-11-07',
  s_updatedAt: '2024-11-07',
  s_actionsEnabled: ''
},
pagination: {
  n_currentPage: 1,
  n_perPage: 10,
  n_totalItems: 10
},
ordering: {
  s_orderBy: '',
  s_orderDirection: 'ASC' | 'DESC'
}
\`\`\`

Respuesta:

\`\`\`typescript

{
  items: interface DictumService[],
  pagination: interface Pagination
}
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 404     | SERVICES_WERE_NOT_FOUND             | No se encontraron servicios con los criterios de búsqueda                                     |

# Update dictum service

Método: PUT

Endpoint: api/v2/public/dictum-services/:ditumServiceID

Body:

\`\`\`typescript
{
  type: 'object',
  properties: {
    petitionNumber: {
      type: 'string',
      pattern: '^[0-9]{2} [0-9]{2} [0-9]{4} [0-9]{7}$'
    },
    inspectionAddress: {
      type: 'string',
      minLength: 1
    },
    requestIsCompleted: {
      type: 'boolean'
    },
    outOfCustomsDate: {
      type: 'string',
      format: 'date'
    }
  }
}
\`\`\`

Response:

\`\`\`typescript
interface DictumService
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | CANNOT_UPDATE_SERVICE_BY_INVOICED             | No se puede actualizar el servicio ya facturado|
| 400     | SERVICE_ALREADY_COMPLETED             | No se puede actualizar ya que la solicitud ya se completó |
| 400     | DICTUM_SERVICE_CANNOT_BE_UPDATED             | Por su estatus actual el servicio no se puede actualizar |
| 400     | OUT_OF_CUSTOM_DATE_CANNOT_BE_MORE_THAN_TODAY             | La fecha de desaduanamiento no puede ser mayor a hoy |
| 400     | OUT_OF_CUSTOM_DATE_CANNOT_BE_BEFORE_SERVICE_CREATION_DATE             | La fecha de desaduanamiento no puede ser menor a la fecha de creación del servicio |
| 400     | PETITION_NUMBER_ALREADY_EXIST             | El número de pedimento ya existe |

# Request Visit

Request para liberar el servicio para ser asignado a inspectores y programar la visita

Método: PUT

Endpoint: /api/v2/public/dictum-services/:dictumServiceID/ready-for-visit

Respuesta:

\`\`\`typescript
interface DictumService
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | SERVICE_IS_NOT_READY_FOR_A_VISIT             | Por el estatus actual del servicio no se peude solicitar visita en sitio (no se ha subido pedimento pagado o no está en estatus "inspecting-products")|
| 400     | SERVICE_IS_ALREADY_WAITING_FOR_A_VISIT             | Ya se ha solicitado la visita en sitio|

# Upload Documents

Endpoint que genera una URL firmada para subir el archivo especificado al almacenamiento de AWS S3

Método: POST

Endpoint: /api/v2/public/dictum-services/:serviceID/documents/:documentType
\`\`\`typescript
type documentType = [
      'petition',
      'paid-petition',
      'commercial-invoice',
      'rectified-petition'
    ];
\`\`\`

Body:

\`\`\`typescript
{
  type: 'object',
  required: ['filePaths'],
  properties: {
    filePaths: {
      type: 'array',
      minItems: 1,
      required: ['path', 'type'],
      properties: {
        path: {
          type: 'string'
        },
        type: {
          type: 'string' // MIME Type, example: application/pdf
        }
      }
    }
  }
}

Example:
{
  documentName: "image-01.jpg",
  path: "image-01.jpg",
  type: "image/jpeg"
}
\`\`\`

Respuesta:

\`\`\`typescript
SignedURL {
  url: string
  signedURL: string | undefined
  type: string
  documentName: string
}

SignedURL[]
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | FILE_WAS_NOT_SENT             | El archivo no se envió o se envió vacío|
| 400     | INVALID_DOCUMENT_TYPE             | El tipo de archivo no existe|
| 400     | SERVICE_ONLY_ACCEPTS_ONE_OF_THIS_TYPE_OF_DOCUMENT             | El servicio sólo admite 1 elemento de este tipo de documento (petition, paid-petition y rectified-petition)|
| 400     | SERVICE_DOES_NOT_ACCEPT_DOCUMENT_MODIFICATIONS             | El servicio ya noa dmite modificaciones en sus documentos|
| 400     | DOCUMENT_ALREADY_EXIST             | El documento ya se ha subido con aterioridad, ya existe|
| 400     | GENERATE_PRE_SIGNED_URL_ERROR             | Por un error desconocido no se pudo generar la ULR prefirmada|

# Add uploaded document path

Este endpoint debe ser llamado después de que la subida del archivo por medio de la URL firmada se haya completado con éxito, el path del documento es la propiedad "url" de la respuesta del endpoint para generar la URL firmada

Método: PUT

Endpoint: /api/v2/public/dictum-services/:serviceID/documents-paths/:documentType
\`\`\`typescript
type documentType = [
      'petition',
      'paid-petition',
      'commercial-invoice',
      'rectified-petition'
    ];
\`\`\`

Body:

\`\`\`typescript
{
  type: 'object',
  required: ['filePaths'],
  properties: {
    filePaths: {
      type: 'array',
      minLength: 1,
      itemsType: 'string
    }
  }
}
\`\`\`

Respuesta:

\`\`\`typescript
interface DictumService
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | SOME_INVOICES_WERE_NOT_FOUND_IN_THE_DOCUMENT             | Algunos folios no fueron encontrados en el documento, es necesario que todos los folios relacionados a este pedimento aparezcan en el documento|
| 400     | NO_PRODUCTS_ARE_ABLE_TO_BE_INSPECTED             | No se encontraron folios para ser inspeccionados en el documento|

# Delete document

Método: DELETE

Endpoint: /api/v2/public/dictum-services/:serviceID/documents

Body:

\`\`\`typescript
{
  type: 'object',
  required: ['documentPath'],
  properties: {
    documentPath: {
      type: 'string',
      minLength: 1
    }
  }
}

Example:
{
  s_documentPath: "clients/65b01717643aaa9f1558efc4/dictum-services/673e3b3d447c3200310497bb/24 40 3743 4013604__petition.pdf"
}
  \`\`\`

Respuesta:

\`\`\`typescript
interface DictumService
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | SERVICE_DOES_NOT_ACCEPT_DOCUMENT_MODIFICATIONS             | El servicio no acepta modificaciones|
| 400     | DOCUMENT_WAS_NOT_FOUND             | No se encontró el archivo|

# Upload paid petition and see products

Endpoint para obtener los folios encontrados dentro del archivo PDF del pedimento pagado

Método: POST

Endpoint: /api/v2/public/dictum-services/:serviceID/documents-upload-paid-petition

Body:

\`\`\`typescript
Archivo atachado
  \`\`\`

Respuesta:

\`\`\`typescript
{
  foundProducts: DictumProduct[]
  notFoundProducts: DictumProduct[]
}
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | SERVICE_ONLY_ACCEPTS_ONE_OF_THIS_TYPE_OF_DOCUMENT             | El servicio sólo acepta 1 de este tipo de documento|
| 400     | DOCUMENT_ALREADY_EXIST             | El documento ya existe|
| 400     | SERVICE_DOES_NOT_ACCEPT_DOCUMENT_MODIFICATIONS             | El servicio ya no acepta modificaciones|
| 400     | PAID_PETITION_DOES_NOT_BELONG_TO_THE_SERVICE             | Se detectó que el archivo no pertenece al servicio (el número de pedimento no se encontró en el contenido del archivo)|
| 400     | NO_PRODUCTS_ARE_ABLE_TO_BE_INSPECTED             | No se encontraron folios para inspeccionar|
| 400     | COULD_NOT_READ_FILE             | No se pudo leer el archiv, posiblemente porque no es formato PDF|

# Upload paid rectified petition and see products

Endpoint para obtener los folios encontrados dentro del archivo PDF del pedimento rectificado

Método: POST

Endpoint: /api/v2/public/dictum-services/:serviceID/documents-upload-rectified-petition

Body:

El archivo de pedimento rectificado debe estar nombrado con el numero de pedimento nuevo, ejemplo: 34 56 8736 0193746.pdf
\`\`\`typescript
Archivo atachado
  \`\`\`

Respuesta:

\`\`\`typescript
{
  foundProducts: DictumProduct[]
  notFoundProducts: DictumProduct[]
}
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | SERVICE_ONLY_ACCEPTS_ONE_OF_THIS_TYPE_OF_DOCUMENT             | El servicio sólo acepta 1 de este tipo de documento|
| 400     | VISIT_ALREADY_REQUEST             | La visita en sitio ya se ha solicitado, por ende ya no se puede subir el epdimento rectificado|
| 400     | RECTIFIED_PETITION_NUMBER_ITS_SAME_AS_PETITION_NUMBER             | El numero de pedimento rectificado no puede ser igual al numero de pedimento original|
| 400     | DOCUMENT_ALREADY_EXIST             | El documento ya existe|
| 400     | PAID_PETITION_DOCUMENT_IS_REQUIRED_FIRST             | El pedimento pagado se requiere ahber sido subido antes de subir le pedimento rectificado|
| 400     | SERVICE_DOES_NOT_ACCEPT_DOCUMENT_MODIFICATIONS             | El servicio ya no acepta modificaciones|
| 400     | ORIGINAL_PETITION_NUMBER_WAS_NOT_FOUND_IN_THE_DOCUMENT             | El número de pedimento original no se encontró en el pedimento rectificado|
| 400     | SOME_INVOICES_WERE_NOT_FOUND_IN_THE_DOCUMENT             | Algunos productos no se encontraron en el pedimento rectificado, todos los folios/productos deben estar presentes |

# Generate all dictums PDF
Método: GET

Endpoint: /api/v2/public/dictum-services/:serviceID/bulk/dictum-of-inspection

Respuesta:

\`\`\`typescript
data {
      files[invoice]: Buffer
}
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | NOT_ABLE_TO_GENERATE_DICTUMS             | La generación de dictamenes no está habilitada aún|
| 400     | PRODUCTS_WERE_NOT_FOUND             | No se encontraron productos del servicio|
| 400     | NO_INVOICE_IS_CONCLUDED             | No se encontraron productos conscluidos para generar su respectivo dictamen|
| 400     | DOCUMENT_EMPTY             | El archivo está vacío|
`,
      7: `# DICTUM PRODUCT
\`\`\`typescript
type DictumProductStatus =
  'invoice-assigned'
  | 'validation-in-progress'
  | 'validated'
  | 'not-validated'
  | 'cancellation-in-progress'
  | 'cancelled'
  | 'in-inspection'
  | 'concluded'
  | 'deleted'
  | 'ghost-invoice';

type NormCodes = '141' | '142' | '050' | '051' | '004' | '189' | '015' | '024' | '020' | '173' | '187' | '003' | '235';

type LabelingMode = 'PB' | 'PA';

interface DictumProductIndicators {
  isGrouped: boolean
  previousVersionsIds: string[] | null
  attachedToTheService: boolean
  SEInvalidationReason: string | null
  sentToSEforConclusion: boolean | null
  sentInLayout1Date: Date | null
  sentInLayout2Date: Date | null
  documentReleaseDate: Date | null
  sendInLayout2: boolean
  dateOfValidation: Date | null
  waitingToExpired: boolean
  NOM003Complement: boolean
}

type DictumProductActionsEnabled = 'edit-products'
  | 'delete-invoice'
  | 'assign-products'
  | 'have-procedure-se';

class DictumProduct {
  id: string
  invoice: string
  subInvoice: string
  status: DictumProductStatus
  client: ClientReference
  collaborator: PersonReference
  service: ServiceReference
  inspector: PersonReference | null
  approver: PersonReference | null
  inspection: InspectionReference | null
  task: TaskReference | null
  tariffFraction: string
  normCode: NormCodes
  fase: NormFases
  labelingMode: LabelingMode
  umc: string
  umcQuantity: number
  labelsToPut: number
  code: string
  code2: string
  brand: string
  presentation: string
  description: string
  indicators: DictumProductIndicators
  round: number
  tracking: ActionEntry[]
  actionsEnabled: DictumProductActionsEnabled[]
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
}
  \`\`\`

# Create Dictum Products

Endpoint: /api/v2/public/dictum-products

Método: POST

Body:

\`\`\`typescript
{
  type: 'object',
  required: ['dictumServiceID', 'products'],
  properties: {
    dictumServiceID: {
      type: 'string',
      minLength: 1
    },
    products: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        required: [
        'normCode',
        'fase',
        'tariffFraction',
        'labelsToPut',
        'umc',
        'umcQuantity',
        'labelingMode',
        'codes',
        'brand',
        'presentation',
        'description'
        ],
        properties: {
          normCode: {
            type: 'string',
            enum: [
              '004',
              '189',
              '141',
              '142',
              '050',
              '051',
              '015',
              '024',
              '020',
              '173',
              '187',
              '235']
          },
          fase: {
            type: 'string',
            enum: ['II', 'III', 'N/A']
          },
          tariffFraction: {
            type: 'string',
            minLength: 8,
            maxLength: 8
          },
          labelsToPut: {
            type: 'number',
            minimum: 1
          },
          umc: {
            type: 'string',
            minLength: 1
          },
          umcQuantity: {
            type: 'number'
          },
          labelingMode: {
            type: 'string',
            enum: ['PA', 'PB']
          },
          codes: {
            type: 'array',
            minItems: 1,
            items: {
              type: 'string',
              minLength: 1
            }
          },
          brand: {
            type: 'string',
            minLength: 1
          },
          presentation: {
            type: 'string',
            minLength: 1
          },
          description: {
            type: 'string',
            minLength: 1
          },
          NOM003Complement: {
            type: 'boolean' // ONLY nom-050
          }
        }
      }
    }
  }
}
\`\`\`

Respuesta:

\`\`\`typescript
interface DictumProduct[]
\`\`\`

Errores
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | CANNOT_CREATE_PRODUCT_BY_INVOICED             | No se puede crear un producto a un servicio facturado|
| 400     | SERVICE_DOES_NOT_ACCEPT_MORE_PRODUCTS             | Debido al estatus del servicio, no se admiten nuevos productos|
| 400     | INVALID_FASE_FOR_NORM_051             | Fase inválida para la norma 051|
| 400     | PRODUCTS_WERE_NOT_CREATED             | Debido a un error desconocido, no fue posible guardar los productos|
| 400     | SERVICE_WAS_NOT_FOUND             | El servicio no se encontró|

# Delete Dictum Product

Endpoint: /api/v2/public/dictum-products

Método: DELETE

Query:

\`\`\`typescript
{
  type: 'object',
  properties: {
    deleteAll: {
      type: 'boolean'
    },
    serviceID: {
      type: 'string'
    },
    productID: {
      type: 'string'
    }
  }
}
\`\`\`

Body: no aplica

Respuesta:

\`\`\`typescript
interface DictumProduct[]
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | CANNOT_MODIFY_PRODUCT_BY_INVOICED             | No se puede eliminar un producto de un servicio facturado|
| 400     | CANNOT_DELETE_INVOICE             | Por su estado actual no se puede eliminar el producto|
| 400     | PRODUCTS_WERE_NOT_FOUND             | No se encontró el/los producto(s)|
| 400     | PRODUCT_WAS_NOT_DELETED             | Por un error desconocido, el producto no pudo eliminarse|

# Delete Grouped Dictum Product

Endpoint: /api/v2/public/dictum-products/grouped/:invoice

Método: DELETE

Query: no aplica

Body: no aplica

Respuesta:

\`\`\`typescript
interface DictumProduct[]
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | CANNOT_MODIFY_PRODUCT_BY_INVOICED             | No se puede eliminar un producto de un servicio facturado|
| 400     | PRODUCTS_ARE_NOT_ABLE_TO_BE_DELETED             | Por su estado actual no se puede eliminar el producto|
| 400     | PRODUCTS_WERE_NOT_FOUND             | No se encontró el/los producto(s)|
| 400     | PRODUCT_WAS_NOT_DELETED             | Por un error desconocido, el producto no pudo eliminarse|

# Get dictum product by ID

Endpoint: /api/v2/public/dictum-products/:productID

Método: GET

Body: no aplica

Respuesta:

\`\`\`typescript
interface DictumProduct
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | PRODUCT_WAS_NOT_FOUND             | No se encontró el folio|

# Get dictum products

Endpoint: /api/v2/public/dictum-products

Método: GET

Query:

Propiedad iniciando por el tipo seguido de guión bajo:

\`\`\`typescript
type 'n' | 'b' | 's' 
number, boolean & string
filters: {
      s_invoice: '',
      s_status: '',
      s_clientBusinessName: '',
      s_clientShortName: '',
      s_collaboratorName: '',
      s_serviceID: '',
      s_serviceNumber: '',
      s_inspectorName: '',
      s_inspectionNumber: '',
      s_taskNumber: '',
      s_tariffFraction: '',
      s_normCode: '',
      s_fase: '',
      s_labelingMode: '',
      s_umc: '',
      s_umcQuantity: '',
      s_labelsToPut: '',
      s_code: '',
      s_code2: '',
      s_brand: '',
      s_presentation: '',
      s_description: '',
      s_createdAt: '',
      s_updatedAt: '',
      s_isGrouped: '',
      b_attachedToTheService: '',
      s_SEInvalidationReason: '',
      s_sentToSEforConclusion: '',
      s_sentInLayout1Date: '',
      s_sentInLayout2Date: '',
      s_documentReleaseDate: '',
      s_outOfDate: '',
      s_dateOfValidation: '',
      b_sendInLayout2: '',
      b_waitingToExpired: '',
      s_actionsEnabled: '',
      s_outOfCustomsDate: '',
      n_modelsQuantity: '',
      s_approver: '',
      s_releaseDate: '',
      s_closedDate: '',
      s_applicationValidity: ''
},
pagination: {
  n_currentPage: 1,
  n_perPage: 10,
  n_totalItems: 10
},
ordering: {
  s_orderBy: '',
  s_orderDirection: 'ASC' | 'DESC'
}
\`\`\`

Body: no aplica

Respuesta:

\`\`\`typescript
{
  items: interface DictumProduct[]
  pagination: interface Pagination
}
\`\`\`

Errores:
| Estatus | Código                             | Significado                                               |
|---------|------------------------------------|-----------------------------------------------------------|
| 400     | PRODUCTS_WERE_NOT_FOUND             | No se encontraron foliso bajo esos criterios de búsqueda|

# Update product

Endpoint: /api/v2/public/dictum-products/:productID

Método: PUT

Body:

\`\`\`typescript
{
  type: 'object',
  required: ['normCode', 'fase', 'tariffFraction', 'labelsToPut', 'umc', 'umcQuantity', 'labelingMode', 'code', 'brand', 'presentation', 'description'],
  properties: {
    normCode: {
      type: 'string',
      enum: [
        '004',
        '189',
        '141',
        '142',
        '050',
        '051',
        '015',
        '024',
        '020',
        '173',
        '187',
        '235']
    },
    fase: {
      type: 'string',
      enum: ['II', 'III', 'N/A']
    },
    tariffFraction: {
      type: 'string',
      minLength: 8,
      maxLength: 8
    },
    labelsToPut: {
      type: 'number',
      minimum: 1
    },
    umc: {
      type: 'string',
      minLength: 1
    },
    labelingMode: {
      type: 'string',
      enum: ['PA', 'PB']
    },
    codes: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'string',
        minLength: 1
      }
    },
    brand: {
      type: 'string',
      minLength: 1
    },
    presentation: {
      type: 'string',
      minLength: 1
    },
    description: {
      type: 'string',
      minLength: 1
    },
    NOM003Complement: {
      type: 'boolean' // Only NOM-050
    }
  }
}
\`\`\`

Respuesta:

\`\`\`typescript
interface Product
\`\`\`

Errores:
| Estatus | Código | Significado |
|---------|--------|-------------|
| 404     | PRODUCT_WAS_NOT_FOUND | El producto no existe, puede ser debido a que no le pertenece al cliente |
| 400     | INVALID_FASE_FOR_NORM_051 | Para la nom 051 se admiten solo la fase 2 y 3 en romano |
| 400     | PRODUCT_WAS_NOT_UPDATED | Debido a un error desconocido, el producto no se pudo actualizar |
| 400     | PRODUCT_CANNOT_BE_UPDATED | Debido a su estatus, el producto no puede actualizarse |
| 400     | THE_PRODUCT_CANNOT_BE_UPDATED_DUE_TO_THE_STATUS_OF_ITS_INSPECTION | Debido a su estatus de su inspección, el producto no puede actualizarse |
| 400     | CANNOT_MODIFY_PRODUCT_BY_INVOICED | No se puede actualizar un producto de un servicio facturado |
| 400     | SERVICE_ALREADY_COMPLETED | La solicitud de servvicio ya se completó, no se pueden actualizar productos |

# Generate Dictum

Endpoint para generar el archivo PDF de Dictamen

Endpoint: /api/v2/public/dictum-products/:productID/dictum-of-inspection

Método: GET

Body:no aplica

Respuesta:

\`\`\`typescript
{
      file: {
        data: Buffer
      }
}
\`\`\`

Errores:
| Estatus | Código | Significado |
|---------|--------|-------------|
| 404     | CANNOT_GENERATE_DICTUM_WHITOUT_INSPECTION_LIST | No se puede generar el dictamen si no existe lista de inspección |
| 404     | PRODUCT_IS_NOT_CONCLUDED | El producto debe estar en estatus concluido para generar el dictamen |
| 404     | NOT_ABLE_TO_GENERATE_DICTUMS | Por falta de pedimento pagado no se puede generar el dictamen |
| 404     | CANNOT_GENERATE_DICTUM_CLIENT_NOT_HAVE_SIGNED_CONTRACT | El cliente debe tener contrato firmado para generar el dictamen |
| 404     | CANNOT_GENERATE_DOCUMENT_NOT_SIGNED_CONTRACT | El cliente debe tener contrato firmado para generar el dictamen |
      `,
      8: `# REVISION SERVICE

\`\`\`typescript
type RevisionServiceStatus =
  'inspecting-products'
  | 'concluded'
  | 'deleted';

interface RevisionServiceIndicators {
  requestIsCompleted: boolean
  invoiced: boolean
  invoicedInfo: InvoicedInfo,
  urgent: boolean
}

type RevisionServiceActionsEnabled = 'edit-service'
  | 'add-product'
  | 'edit-products'
  | 'delete-service'
  | 'assign-products'
  | 'register-invoiced';

class RevisionService {
  id: string
  requestNumber: string
  status: RevisionServiceStatus
  client: ClientReference
  collaborator: PersonReference
  proposedValidationDate: Date | null
  products: RevisionProduct[]
  indicators: RevisionServiceIndicators
  tracking: ActionEntry[]
  actionsEnabled: RevisionServiceActionsEnabled[]
  createdAt: Date
  updatedAt: Date
}
\`\`\`


# Create Revision Service
**Método**: POST

**Endpoint**: /api/v2/public/revision-services

**Body**: NO aplica

**Respuesta**:

\`\`\`tsx
interface RevisionService
\`\`\`

**Errores**:
| Estatus | Código                    | Significado                                                                 |
|---------|---------------------------|-----------------------------------------------------------------------------|
| 404     | CLIENT_WAS_NOT_FOUND       | El cliente no existe                                                         |
| 404     | COLLABORATOR_WAS_NOT_FOUND | El colaborador no existe                                                     |
| 400     | SERVICE_WAS_NOT_CREATED    | Por un error desconocido, no se pudo guardar el servicio                     |

# Delete Constancy Service

**Método**: DELETE

**Endpoint**: api/v2/public/revision-services/:serviceID

**Body**: No aplica

**Response**:

\`\`\`typescript
interface RevisionService
\`\`\`

**Errores**:
| Estatus | Código                                        | Significado                                                                 |
|---------|-----------------------------------------------|-----------------------------------------------------------------------------|
| 404     | SERVICE_WAS_NOT_FOUND                        | El servicio no existe                                                        |
| 400     | PRODUCTS_ARE_NOT_ABLE_TO_BE_DELETED          | Debido a su estatus, los productos no pueden ser eliminados                  |
| 400     | SERVICE_IS_NOT_ABLE_TO_BE_DELETED            | Debido a su estatus, el servicio no puede ser eliminado                      |
| 400     | PRODUCTS_WERE_NOT_DELETED                    | Por un error desconocido, los productos no pudieron eliminarse               |
| 400     | CANNOT_DELETE_SERVICE                      | Por un error desconocido, el servicio no pudo eliminarse                     |
| 400     | CANNOT_UPDATE_SERVICE_BY_INVOICED                         | El servicio no puede eliminarse porque ya se facturó                     |

# Get service by ID

**Método**: GET

**Endpoin**: api/v1/public/revision-services/:serviceID

**Body**: No aplica

**Respuesta**:

\`\`\`typescript
interface RevisionService
\`\`\`

**Errores**:
| Estatus | Código                 | Significado        |
|---------|------------------------|--------------------|
| 404     | SERVICE_WAS_NOT_FOUND   | El servicio no existe |


# Get services

**Método**: GET

**Endpoint**: api/v2/public/revision-services

**Body**: No aplica

**Query**:


Propiedad iniciando por el tipo seguido de guión bajo:
\`\`\`typescript
type 'n' | 'b' | 's' 
number, boolean & string
filters: {
    s_requestNumber: '',
    s_status, type: '',
    s_clientBusinessName: '',
    s_clientShortName: '',
    s_collaboratorName: '',
    s_expirationDate: '',
    s_proposedValidationDate: '',
    s_requestIsCompleted: '',
    s_invoiced: '',
    s_invoiceNumber: '',
    s_invoicedInfoDate: '',
    s_UUID: '',
    s_createdAt: '',
    s_updatedAt: '',
    s_indicatorsUrgent: '',
    s_actionsEnabled: '',
    n_modelsQuantity: ''
},
pagination: {
  n_currentPage: 1,
  n_perPage: 10,
  n_totalItems: 10
},
ordering: {
  s_orderBy: '',
  s_orderDirection: 'ASC' | 'DESC'
}
\`\`\`

**Respuesta**:

\`\`\`typescript

{
items: interface RevisionService[],
pagination: interface Pagination
}
\`\`\`

**Errores**:
| Estatus | Código                    | Significado                                                       |
|---------|---------------------------|-------------------------------------------------------------------|
| 404     | SERVICES_WERE_NOT_FOUND    | No se encontraron servicios con los criterios de búsqueda         |

# Update revision service

**Principalmente para actualizar el indicador**: \`\`\`requestIsCompleted\`\`\`, para definir que ya se puede continuar con acciones posteriores del servicio, como asignar inspectores

**Método**: PUT

**Endpoint**: api/v2/public/revision-services/:serviceID

**Body**:

\`\`\`typescript
{
  type: 'object',
  properties: {
    requestIsCompleted: {
      type: 'boolean'
    }
}
\`\`\`

**Response**:

\`\`\`typescript
interface RevisionService
\`\`\`

**Errores**:
| Estatus | Código                    | Significado                                                        |
|---------|---------------------------|--------------------------------------------------------------------|
| 404     | SERVICE_WAS_NOT_FOUND      | El servicio no existe.                                             |
| 400     | SERVICE_WAS_NOT_UPDATED    | Por un error desconocido, el servicio no pudo actualizarse.       |
| 400     | CANNOT_UPDATE_SERVICE_BY_INVOICED    | El servicio no se pudo actualizar porque ya se facturó       |
| 400     | SERVICE_ALREADY_COMPLETED    | No se pudo actualizar porque ya se completó la creación de la solicitud       |
| 400     | SERVICE_DOES_NOT_HAVE_PRODUCTS    | No se pudo actualizar porque el servicio no tiene productos dados de alta       |

      `,
      9: `# REVISION PRODUCT
\`\`\`typescript
export type RevisionProductStatus =
  'in-inspection'
  | 'concluded'
  | 'deleted';

export interface RevisionProductIndicators {
  documentReleaseDate: Date | null
  design: boolean
  package: string
  long: string
  high: string
  radio: string
  urgent: boolean
  NOM003Complement: boolean
}

export type RevisionProductActionsEnabled = 'edit-products'
  | 'upload-files'
  | 'delete-invoice'
  | 'assign-products';

class RevisionProduct {
  id: string
  invoice: string
  status: RevisionProductStatus
  client: ClientReference
  collaborator: PersonReference
  service: ServiceReference
  inspector: PersonReference | null
  approver: PersonReference | null
  inspection: InspectionReference | null
  task: TaskReference | null
  normCode: NormCodes
  fase: NormFases
  code: string
  brand: string
  presentation: string
  description: string
  documents: string[]
  tracking: ActionEntry[]
  indicators: RevisionProductIndicators
  round: number
  actionsEnabled: RevisionProductActionsEnabled[]
  createdAt: Date
  updatedAt: Date
}
\`\`\`

# Create Revision Products

**Endpoint**: /api/v2/public/revision-products

**Métod**o: POST

**Body**:

\`\`\`typescript
{
  type: 'object',
  required: ['revisionServiceID', 'products'],
  properties: {
    revisionServiceID: {
      type: 'string',
      minLength: 1
    },
    products: {
      type: 'array',
      minItems: 1,
      items: {
        type: 'object',
        required: ['normCode', 'fase', 'code', 'brand', 'presentation', 'description'],
        properties: {
          normCode: {
            type: 'string',
            enum: [
              '004',
              '189',
              '141',
              '142',
              '050',
              '051',
              '015',
              '024',
              '020',
              '173',
              '187',
              '235']
          },
          fase: {
            type: 'string',
            enum: ['II', 'III', 'N/A']
          },
          code: {
            type: 'string',
            minLength: 1
          },
          brand: {
            type: 'string',
            minLength: 1
          },
          presentation: {
            type: 'string',
            minLength: 1
          },
          description: {
            type: 'string',
            minLength: 1
          },
          design: {
            type: 'boolean'
          },
          NOM003Complement: {
            type: 'boolean' // only NOM-050
          }
        }
      }
    }
  }
}
\`\`\`

**Respuesta**:

\`\`\`typescript
interface RevisionProduct[]
\`\`\`

**Errores**
| Estatus | Código                     | Significado                                                                                     |
|---------|----------------------------|-------------------------------------------------------------------------------------------------|
| 404     | SERVICE_WAS_NOT_FOUND      | El servicio no existe, podría ser debido a que no le pertenece al cliente en la API pública     |
| 400     | INVALID_FASE_FOR_NORM_051  | Para la norma 051 solo se aceptan las fases 1, 2 y 3 en números romanos                         |
| 400     | PRODUCTS_WERE_NOT_CREATED  | Debido a un error desconocido, no fue posible guardar los productos                             |
| 400     | CANNOT_CREATE_PRODUCT_BY_INVOICED  | No se puede crear el producto ya que el servicio ya está facturado                             |

# Delete Revision Products

Endpoint: /api/v2/public/revision-products

Método: DELETE

Query:

\`\`\`typescript
{
  type: 'object',
  properties: {
    deleteAll: {
      type: 'boolean'
    },
    serviceID: {
      type: 'string'
    },
    productID: {
      type: 'string'
    }
  }
}
\`\`\`

Body: no aplica

Respuesta:

\`\`\`typescript
interface RevisionProduct[]
\`\`\`

Errores:
| Estatus | Código                              | Significado                                                                       |
|---------|-------------------------------------|-----------------------------------------------------------------------------------|
| 404     | PRODUCTS_WERE_NOT_FOUND            | No se encontraron los productos, puede ser porque no le pertenecen al cliente    |
| 400     | PRODUCT_WAS_NOT_DELETED            | Por un error desconocido, el producto no pudo eliminarse                         |
| 400     | CANNOT_DELETE_INVOICE| Debido a su status, el producto no puede eliminarse                               |
| 400     | CANNOT_MODIFY_PRODUCT_BY_INVOICED| No se puede eliminar el producto ya que el servicio ya se facturó                               |

# Get Revision product by ID

Endpoint: /api/v2/public/revision-products/:productID

Body: no aplica

Respuesta:

\`\`\`typescript
interface RevisionProduct
\`\`\`

Errores:
| Estatus | Código                | Significado                                                             |
|---------|-----------------------|-------------------------------------------------------------------------|
| 404     | PRODUCT_WAS_NOT_FOUND | El producto no existe |

# Get Revision products

Endpoint: /api/v2/public/revision-products

Método: GET

Query:

Propiedad iniciando por el tipo seguido de guión bajo:
\`\`\`typescript
type 'n' | 'b' | 's' 
number, boolean & string
filters {
      s_invoice: '',
      s_status: '',
      s_clientBusinessName: '',
      s_clientShortName: '',
      s_collaboratorName: '',
      s_requestNumber: '',
      s_serviceID: '',
      s_inspectorName: '',
      s_inspectionNumber: '',
      b_indicatorsDesign: '',
      b_indicatorsUrgent: '',
      s_taskNumber: '',
      s_normCode: '',
      s_fase: '',
      s_code: '',
      s_brand: '',
      s_presentation: '',
      s_description: '',
      s_createdAt: '',
      s_updatedAt: '2024-11-02',
      b_lookInTheTrash: 'true'
},
pagination: {
  n_currentPage: 1,
  n_perPage: 10,
  n_totalItems: 10
},
ordering: {
  s_orderBy: '',
  s_orderDirection: 'ASC' | 'DESC'
}
\`\`\`

Body: no aplica

Respuesta:

\`\`\`typescript
{
  items: interface RevisionProduct[]
  pagination: interface Pagination
}
\`\`\`

Errores:
| Estatus | Código                | Significado                                     |
|---------|-----------------------|-------------------------------------------------|
| 404     | PRODUCTS_WERE_NOT_FOUND | No se encontraron productos bajo el criterio de búsqueda |

# Update Revision product

Endpoint: /api/v2/public/revision-products/:productID

Método: PUT

Body:

\`\`\`typescript
{
  type: 'object',
  required: ['normCode', 'fase', 'code', 'brand', 'presentation', 'description'],
  properties: {
    normCode: {
      type: 'string',
      enum: [
        '004',
        '189',
        '141',
        '142',
        '050',
        '051',
        '015',
        '024',
        '020',
        '173',
        '187',
        '235']
    },
    fase: {
      type: 'string',
      enum: ['II', 'III', 'N/A']
    },
    code: {
      type: 'string',
      minLength: 1
    },
    brand: {
      type: 'string',
      minLength: 1
    },
    presentation: {
      type: 'string',
      minLength: 1
    },
    description: {
      type: 'string',
      minLength: 1
    },
    design: {
      type: 'boolean'
    },
    NOM003Complement: {
      type: 'boolean' // only NOM-050
    }
  }
}
\`\`\`

Respuesta:

\`\`\`typescript
interface RevisionProduct
\`\`\`

Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 404     | PRODUCT_WAS_NOT_FOUND      | El producto no existe, puede ser debido a que no le pertenece al cliente     |
| 400     | INVALID_FASE_FOR_NORM_051  | Para la nom 051 se admiten solo la fase 2 y 3 en romano                   |
| 400     | PRODUCT_WAS_NOT_UPDATED    | Debido a un error desconocido, el producto no se pudo actualizar             |
| 400     | CANNOT_UPDATE_PRODUCT  | Debido a su estado, el producto no pudo ser actualizado                      |
| 400     | VALIDATION_ERROR  | Los datos no fueron enviados correctamente                      |

# Upload Product Documents

Endpoint que genera una URL firmada para subir el archivo especificado al almacenamiento de AWS S3

Método: POST

Endpoint: /api/v2/public/revision-products/:productID/documents

Body: 
\`\`\`typescript
{
  type: 'object',
  required: ['filePaths'],
  properties: {
    filePaths: {
      type: 'array',
      minItems: 1,
      required: ['path', 'type'],
      properties: {
        path: {
          type: 'string'
        },
        type: {
          type: 'string' // MIME Type, example: application/pdf
        }
      }
    }
  }
}

Example:
{
  documentName: "image-01.jpg",
  path: "image-01.jpg",
  type: "image/jpeg"
}
\`\`\`


Respuesta:

\`\`\`typescript
SignedURL {
  url: string
  signedURL: string | undefined
  type: string
  documentName: string
}

SignedURL[]
\`\`\`

Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 400     | FILE_WAS_NOT_SENT      | El archivo no se envió o se envió vacío     |
| 400     | PRODUCT_DONT_ABLE_TO_UPLOAD_DOCUMENTS  | Debido al estatus del producto no se admite la subida de archivos                   |
| 400     | DOCUMENT_ALREADY_EXIST    | El documento ya se ha subido, ya existe             |
| 400     | GENERATE_PRE_SIGNED_URL_ERROR  | Debido a un eror desconocido no se pudo generar la URL firmada                     |

# Add uploaded document path

Este endpoint debe ser llamado después de que la subida del archivo por medio de la URL firmada se haya completado con éxito,
el path del documento es la propiedad "url" de la respuesta del endpoint para generar la URL firmada

Método: PUT

Endpoint: /api/v2/public/revision-products/:productID/update-documents-paths

Body: 
\`\`\`typescript
{
  type: 'object',
  required: ['filePaths'],
  properties: {
    filePaths: {
      type: 'array',
      minLength: 1,
      itemsType: 'string
    }
  }
}
\`\`\`

Respuesta:

\`\`\`typescript
interface RevisionProduct
\`\`\`

# Delete document

Método: DELETE

Endpoint: /api/v2/public/revision-products/:productID/documents

Body: 
\`\`\`typescript
{
  type: 'object',
  required: ['documentPath'],
  properties: {
    documentPath: {
      type: 'string',
      minLength: 1
    }
  }
}

Example:
{
  s_documentPath: "clients/66cce34f55fc4900304c22f0/revision-products/66eb167c2b184c0030bf783b/round-1IMG-20240829-WA0054.jpg"
}
\`\`\`

Respuesta:

\`\`\`typescript
interface RevisionProduct
\`\`\`


Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 400     | DOCUMENT_WAS_NOT_FOUND      | El archivo no se encontró     |

# Mark changed files

Endpoint para marcar como subidos archivos para nueva ronda de un producto de revision, ésto nos indica que podemos seguir con la siguiente ronda de inspección

Método: PUT

Endpoint: /api/v2/public/revision-products/:productID/mark-changed-files

Body: No aplica

Respuesta:

\`\`\`typescript
interface RevisionProduct
\`\`\`

Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 400     | ROUND_DOCUMENTS_ALREADY_UPLOADED      | Los documentos de la ronda ya se han subido     |

      `,
      10: `# DOCUMENTS
      
# Get signed URL

Obtener la URL firmada desde el almacenamiento para descargar un documento

Método: GET

Endpoint: /api/v2/public/documents/signature

Body: No aplica

Query:

\`\`\`typescript
{
  type: 'object',
  required: ['filePath'],
  properties: {
    filePath: {
      type: 'string',
      minLength: 1
    }
  }
}

Example:
"/api/v2/public/documents/signature?s_filePath=clients/66cce34f55fc4900304c22f0/revision-products/66eb167c2b184c0030bf783b/round-1IMG-20240829-WA0054.jpg"
\`\`\`

Respuesta:

\`\`\`typescript
string
\`\`\`

# Generate service request

Generar el documento de solicitud de servicio

Método: GET

Endpoint: /api/v2/public/documents/service-request/:serviceType/:serviceID

\`\`\`typescript
Example: "/api/v2/public/documents/service-request/DC/66cce34f55fc4900304c22f0"
\`\`\`

Respuesta:

\`\`\`typescript
{
      file: {
        data: Buffer
      }
}
\`\`\`

      `,
      11: `# INSPECTIONS

\`\`\`typescript
export type InspectionStatus = 'in-progress' | 'in-review' | 'concluded' | 'deleted';

export type InspectionSteps = 'product-updated' | 'list-completed' | 'dictaminate' | 'uploaded-evidence' | 'uploaded-inspection-format';

export type MeasurementEquipment = 'M-01' | 'M-02' | 'M-03' | 'M-04';

export interface Question {
  answer: (boolean | null | 0)
  observations: string
  haveEvidence: boolean
  evidence: string
}

export interface Packing {
  type: 'circular' | 'rectangular' | 'cylindrical'
  height: number | null
  width: number | null
  radius: number | null
  content: number
  productType: 'solido g' | 'liquido ml'
}

export interface Sampling {
  lot: number
  sample: number
  accepted: number
  rejected: number
  comments?: string
}

export interface InspectionList {
  inspectorName: string
  measurementEquipment: MeasurementEquipment
  clientBusinessName: string
  productDescription: string
  generalObservations: string
  questions: Question[]
  packing: Packing | null
  sampling: Sampling | null
  model: string
  section: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | ''
  answers: any
  sign: string | null
}

export interface PausePeriod {
  start: Date | null
  end: Date | null
}

export interface Round {
  id: number
  steps: InspectionSteps[]
  complies: boolean | null
  files: string[]
  task: TaskReference | null
  productNotPresented: boolean
  inspector: PersonReference | null
  approver: PersonReference | null
  list: InspectionList | null
  listDate: Date | null
  startAt: Date | null
  endsAt: Date | null
  startInspectionListDate: Date | null
  inspectionListDate: Date | null
  uploadedEvidenceDate: Date | null
  approvedDate: Date | null
  inspectionListVersion: number
  numberOfDisapprovals: number
  pausePeriods: PausePeriod[]
}

export interface ReviewComment {
  id: number
  author: PersonReference
  date: string
  text: string
  round: number
  isAFile: boolean
}

export interface InspectionIndicators {
  sendToNegation: boolean
  waitingForFilesForRemoteRound: boolean
  changedFilesForRemoteRound: boolean
  editInspection: boolean
  waitingToExpired: boolean
  inspectionDate: Date | null
  isReadyForVisit: boolean
  design: boolean
  urgent: boolean
}

class Inspection {
  id: string
  number: string
  task: TaskReference | null
  scheduled: boolean
  status: InspectionStatus
  complies: boolean | null
  approved: boolean | null
  inspector: PersonReference | null
  approver: PersonReference | null
  service: ServiceReference
  product: ProductReference
  client: ClientReference
  collaborator: PersonReference
  listCompleted: boolean
  evidence: string[]
  rounds: Round[]
  norm: NormCodes
  fase: NormFases | null
  reviewComments: ReviewComment[]
  tracking: ActionEntry[]
  indicators: InspectionIndicators
  releaseDate: Date | null
  createdAt: Date
  updatedAt: Date
}
\`\`\`
      
# Get Inspection By ID

Método: GET

Endpoint: /api/v2/public/inspections/:inspectionID

Respuesta:

\`\`\`typescript
interface Inspection
\`\`\`

Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 404     | INSPECTION_WAS_NOT_FOUND      | La inspección no existe     |

# Get Inspections

**Método**: GET

**Endpoint**: /api/v2/public/inspections

**Query**:

Propiedad iniciando por el tipo seguido de guión bajo:
\`\`\`typescript
type 'n' | 'b' | 's' 
number, boolean & string
filters: {
    s_number
    s_status
    b_complies
    b_approved
    s_taskID
    s_taskNumber
    s_roundInspectorID
    s_roundTaskID
    s_roundTaskNumber
    s_roundStartAt
    s_inspectorID
    s_inspectorName
    s_approverID
    s_approverName
    s_serviceID
    s_serviceCode
    s_serviceNumber
    s_productCode
    s_productInvoice
    s_productID
    s_clientID
    s_clientBusinessName
    s_clientShortName
    s_collaboratorID
    s_collaboratorName
    s_norm
    s_fase
    s_releaseDate
    s_createdAt
    s_updatedAt
    s_editInspection
    b_waitingForFilesForRemoteRound
    b_changedFilesForRemoteRound
    b_isReadyForVisit
},
pagination: {
  n_currentPage: 1,
  n_perPage: 10,
  n_totalItems: 10
},
ordering: {
  s_orderBy: '',
  s_orderDirection: 'ASC' | 'DESC'
}
\`\`\`

**Respuesta**:
\`\`\`typescript
{
  items: Inspetion[],
  pagination: Pagination
}
\`\`\`

# Generate inspection format

**Método**: GET

**Endpoint**: /api/v2/public/inspections/:inspectionID/:roundID/inspection-format

**Respuesta**:
\`\`\`typescript
{
      file: {
        data: Buffer
      }
}
\`\`\`

      `,
      12: `# TASKS

      
\`\`\`typescript
export type TaskType = 'onsite-inspection' | 'remote-inspection' | 'constancy-inspection' | 'revision-inspection';

export type TaskStatus = 'scheduled' | 'in-progress' | 'concluded' | 'deleted';

export interface TaskResponsible extends PersonReference {
  picture: string
}

export interface Witness {
  name: string
  signature: string
}

export interface PlanificationSignature {
  name: string
  signature: string
}

export interface Visit {
  address: string
  time: string | null
  witnesses: Witness[]
  planificationSignature: PlanificationSignature
  clientStatements: string | null
  visitorStatements: string | null
}

export interface DatesRegistry {
  scheduledTo: Date
  startedAt: Date | null
  endedAt: Date | null
}

export interface TaskIndicators {
  inspectionsQuantity: number
  completedInspectionsQuantity: number
  completedInspectionListsQuantity: number
  editInspection: boolean
  waitingToExpired: boolean
  urgent: boolean
}

class Task {
  id: string
  number: string
  type: TaskType
  status: TaskStatus
  responsible: TaskResponsible
  client: ClientReference
  visit: Visit | null
  invoices: string[]
  services: string[]
  comments: string | null
  datesRegistry: DatesRegistry
  indicators: TaskIndicators
  tracking: ActionEntry[]
  createdAt: Date
  updatedAt: Date
}
\`\`\`

# Get Task By ID

Método: GET

Endpoint: /api/v2/public/tasks/:taskID

Respuesta:

\`\`\`typescript
interface Task
\`\`\`

Errores:
| Estatus | Código                     | Significado                                                                  |
|---------|----------------------------|------------------------------------------------------------------------------|
| 404     | TASK_WAS_NOT_FOUND      | La tarea no se encontró     |

# Get Tasks

**Método**: GET

**Endpoint**: /api/v2/public/inspections

**Query**:

Propiedad iniciando por el tipo seguido de guión bajo:
\`\`\`typescript
type 'n' | 'b' | 's' 
number, boolean & string
filters: {
    s_number: '',
    s_type: '',
    s_status: '',
    s_responsibleID: '',
    s_responsibleName: '',
    s_clientBusinessName: '',
    s_clientShortName: '',
    s_clientID: '',
    s_visitAddress: '',
    s_scheduledTo: '',
    s_startedAt: '',
    s_endedAt: '',
    s_inspectionsQuantity: '',
    s_completedInspectionsQuantity: '',
    s_completedInspectionListsQuantity: '',
    s_createdAt: '',
    s_updatedAt: '',
    s_editInspection: ''
},
pagination: {
  n_currentPage: 1,
  n_perPage: 10,
  n_totalItems: 10
},
ordering: {
  s_orderBy: '',
  s_orderDirection: 'ASC' | 'DESC'
}
\`\`\`

**Respuesta**:
\`\`\`typescript
{
  items: Task[],
  pagination: Pagination
}
\`\`\`

      `
    };

    return info[selectedItem];
  };

  return (
    <div className="documentation">
      <Header
        title={'Documentación CECSA API'}
        showBackbutton={true}
      />
      <div className="documentation__main">
        <Tabs
          items={[
            {
              id: 1,
              label: 'Common definitions'
            },
            {
              id: 2,
              label: 'Client'
            },
            {
              id: 3,
              label: 'Collaborator'
            },
            {
              id: 4,
              label: 'Constancy Service'
            },
            {
              id: 5,
              label: 'Constancy Products'
            },
            {
              id: 6,
              label: 'Dictum Service'
            },
            {
              id: 7,
              label: 'Dictum Product'
            },
            {
              id: 8,
              label: 'Revision Service'
            },
            {
              id: 9,
              label: 'Revision Product'
            },
            {
              id: 10,
              label: 'Documents'
            },
            {
              id: 11,
              label: 'Inspections'
            },
            {
              id: 12,
              label: 'Tasks'
            }
          ]}
          onSelectTab={(_value: TabItem) => {
            setSelectedItem(_value.id);
          }}
        />
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeHighlight]}
          className="documentation__code"
        >
          {renderContent()}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default Profile;
