import React from 'react';

import './styles.scss';

interface subTitleType {
  label: string | number
  title: string
}

interface Props {
  title: string
  subTitle?: subTitleType[]
  showBackbutton?: boolean
}

const Header: React.FC<Props> = (props) => {
  const {
    title,
    subTitle = [],
    showBackbutton
  } = props;

  return (
    <div className={'header'} id='header'>
      {
        showBackbutton && (
          <>
            <div className='header__back-button__complement'></div>
          </>
        )
      }
      <p className='header__title'>
        {title}
      </p>
      <div className='header__sub-title-container'>
        {
          subTitle.map((subTitleItem: subTitleType, index: number) => (
            <p className='header__sub-title' key={index}>
              <span className='header__sub-title--label'>{`${subTitleItem.title} ${subTitleItem.title ? ': ' : ''}`}</span>
              {subTitleItem.label}
            </p>
          ))
        }
      </div>
    </div>
  );
};

export default Header;
